import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
const defaultPagination = {
    page: 1,
    limit: 9999
};

export async function requestUpgradeLicense(store_uuid, number_of_licenses, current_license) {
    const payload = {store_uuid, number_of_licenses, current_license}
    const result = await axios.post('/api/request-upgrade-license', payload, config);
    return result.data;
}
