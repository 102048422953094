import React, {useEffect} from "react";
import {formatPrice, formatQty} from "../helpers/number";

const GHValueDual = (props) => {
    const isLoading = props.loading;

    const value = props.value;
    const label = props.label;
    const value2 = props.value2;
    const label2 = props.label2;

    const comingSoon = props?.comingSoon || false;
    const updates = props?.updates || null;

    const svgIcon = props?.svgIcon || null;
    const svgIcon2 = props?.svgIcon2 || null;

    const totalQty = props?.totalQty || null;
    const totalGmv = props?.totalGmv || null;

    return (
        <div
            className={`kpi pb-4 gh-value-box d-flex flex-row justify-content-between text-start h-100 overflow-hidden position-relative flex-grow-1 ${comingSoon ? 'gh-coming-soon' : ''}`}
            style={{minHeight: '160px', flexDirection: 'column'}}>
            <div className='w-50'>
                <div className="d-flex justify-content-start align-items-center"
                     style={{opacity: comingSoon ? '0.4' : '1'}}>
                    {
                        svgIcon !== null &&
                        <div className="gh-svg-icon">
                            {svgIcon}
                        </div>
                    }
                </div>

                <div className="my-4 d-flex justify-content-start align-items-center"
                     style={{opacity: comingSoon ? '0.4' : '1'}}>
                    <h2 className='m-0' style={{color: '#9291A5', fontSize: '15px', fontWeight: ''}}>{label}</h2>
                </div>

                <div className="details mb-2 h-100" style={{opacity: comingSoon ? '0.4' : '1', minHeight: '34px'}}>
                    {
                        isLoading ?
                            <div className="demo-inline-spacing">
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            <span className="increase fs-4 gh-dashboard-values"
                                  style={{fontWeight: '600'}}>{value}</span>
                    }

                    {
                        updates && updates.increase &&
                        <small className="text-success fw-medium">
                            <i className="bx bx-up-arrow-alt"></i> {updates.value}
                        </small>
                    }
                    {
                        updates && !updates.increase &&
                        <small className="text-danger fw-medium">
                            <i className="bx bx-down-arrow-alt"></i> {updates.value}
                        </small>
                    }
                </div>
            </div>
            <div className='w-50'>
                <div className="d-flex justify-content-start align-items-center"
                     style={{opacity: comingSoon ? '0.4' : '1'}}>
                    {
                        svgIcon2 !== null &&
                        <div className="gh-svg-icon">
                            {svgIcon2}
                        </div>
                    }
                </div>

                <div className="my-4 d-flex justify-content-start align-items-center"
                     style={{opacity: comingSoon ? '0.4' : '1'}}>
                    <h2 className='m-0' style={{color: '#9291A5', fontSize: '15px', fontWeight: ''}}>{label2}</h2>
                </div>

                <div className="details mb-2 h-100" style={{opacity: comingSoon ? '0.4' : '1', minHeight: '34px'}}>
                    {
                        isLoading ?
                            <div className="demo-inline-spacing">
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            <span className="increase fs-4 gh-dashboard-values"
                                  style={{fontWeight: '600'}}>{value2}</span>
                    }

                    {
                        updates && updates.increase &&
                        <small className="text-success fw-medium">
                            <i className="bx bx-up-arrow-alt"></i> {updates.value}
                        </small>
                    }
                    {
                        updates && !updates.increase &&
                        <small className="text-danger fw-medium">
                            <i className="bx bx-down-arrow-alt"></i> {updates.value}
                        </small>
                    }
                </div>
            </div>
        </div>
    )
}
export default GHValueDual
