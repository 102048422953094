import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Chart = ({graphData}) => {
  const data = {
    labels: graphData.map(d => d.month),
    datasets: [
      {
        label: 'Products',
        data: graphData.map(d => d.hints),
        backgroundColor: '#fda328',
        borderColor: '#fda328',
        fill: false
      },
      {
        label: 'Orders',
        data: graphData.map(d => d.orders),
        backgroundColor: '#2e243a',
        borderColor: '#2e243a',
        fill: false
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default Chart;
