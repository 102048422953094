import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import {Routes, Route} from 'react-router-dom';
import GifthintValue from "./pages/GifthintValue";
import Customers from "./pages/Customers";
import CuratedHints from "./pages/CuratedHints";
import Orders from "./pages/Orders";
import License from "./pages/License";
import Hints from "./pages/Hints";
import OrdersandReturns from "./pages/OrdersandReturns";
import AffiliateGroups from "./pages/AffiliateGroups";
import Login from "./account/Login";
import PrivateRoute from './account/PrivateRoute'
import ProfileSettings from "./account/ProfileSettings";
import Security from "./account/Security";
import Merchants from "./pages/Merchants";
import CustomerSignUp from "./account/CustomerSignUp";
import MerchantSignUp from "./account/MerchantSignUp";
import LoginByPhone from "./account/LoginByPhone";
import Promotions from "./pages/Promotions";
import Analytics from "./pages/Analytics";
import SuggestedHints from "./pages/SuggestedHints";
import Users from "./pages/Users";
import Affiliates from "./pages/Affiliates";
import CustomerDetails from "./pages/CustomerDetails";
import Settings from "./pages/Settings";
import MerchantDetails from "./pages/MerchantDetails";
import ChangePassword from "./account/ChangePassword";
import PromotionsTest from "./pages/PromotionsTest";
import ShopifyDiscounts from "./pages/ShopifyDiscounts";
import Feedback from "./pages/Feedback";
import {Toaster} from 'react-hot-toast';
import MerchantAffiliates from "./pages/MerchantAffiliates";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

const App = () => {
    const auth = localStorage.getItem('accessToken') !== null || localStorage.getItem('accessToken') !== '' || localStorage.getItem('accessToken') !== undefined ? localStorage.getItem('accessToken') : null; // determine if authorized, from context or however you're doing it
    return auth ? <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page" style={{marginLeft: '260px'}}>
                    <div className="container-xxl">
                        <Navbar/>
                    </div>
                    <div className="content-wrapper" style={{marginTop: '74px'}}>
                        <Routes>
                            <Route exact path="/" element={<Dashboard/>}/>
                            <Route exact path="/gyfthint-value" element={<GifthintValue/>}/>
                            <Route exact path="/customers" element={<Customers/>}/>
                            <Route exact path="/customers/:id" element={<CustomerDetails/>}/>
                            <Route exact path="/merchants" element={<Merchants/>}/>
                            <Route exact path="/merchants/:id" element={<MerchantDetails/>}/>
                            <Route exact path="/curated-hints" element={<CuratedHints/>}/>
                            <Route exact path="/orders" element={<Orders/>}/>
                            <Route exact path="/license" element={<License/>}/>
                            <Route exact path="/hints" element={<Hints/>}/>
                            <Route exact path="/orders-and-returns" element={<OrdersandReturns/>}/>
                            <Route exact path="/manage-affiliates" element={<Affiliates/>}/>
                            <Route exact path="/profile-settings" element={<ProfileSettings/>}/>
                            <Route exact path="/profile-security" element={<Security/>}/>
                            <Route exact path="/promotions" element={<Promotions/>}/>
                            <Route exact path="/shopify-discounts" element={<ShopifyDiscounts/>}/>
                            <Route exact path="/promotions-test" element={<PromotionsTest/>}/>
                            <Route exact path="/analytics" element={<Analytics/>}/>
                            <Route exact path="/suggested-hints" element={<SuggestedHints/>}/>
                            <Route exact path="/manage-users" element={<Users/>}/>
                            <Route exact path="/settings" element={<Settings/>}/>
                            <Route exact path="/feedback" element={<Feedback/>}/>
                            <Route exact path="/affiliate-groups" element={<AffiliateGroups/>}/>
                            <Route exact path="/merchant-affiliates" element={<MerchantAffiliates/>}/>
                        </Routes>
                        <Footer/>
                    </div>
                </div>
            </div>
            <Toaster position="top-right"
                     toastOptions={{
                         duration: 5000,
                         style: {
                             background: '#363636',
                             color: '#fff',
                             fontSize: '18px',
                             borderRadius: '8px',
                             padding: '20px',
                         },
                         icon: '🔥',
                         success: {
                             style: {
                                 background: '#007600',
                             },
                             icon: '✅',
                         },
                         error: {
                             style: {
                                 background: '#F44738',
                             },
                             icon: '❌',
                         },
                     }}/>
        </div> :
        <>
            <Routes>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/change-password" element={<ChangePassword/>}/>
                <Route exact path="/login-by-phone" element={<LoginByPhone/>}/>
                <Route exact path="/customer-signup" element={<CustomerSignUp/>}/>
                <Route exact path="/merchant-signup" element={<MerchantSignUp/>}/>
                <Route exact path="/" element={<Dashboard/>}/>
                <Route exact path="/gyfthint-value" element={<GifthintValue/>}/>
                <Route exact path="/customers" element={<Customers/>}/>
                <Route exact path="/customers/:id" element={<CustomerDetails/>}/>
                <Route exact path="/merchants" element={<Merchants/>}/>
                <Route exact path="/merchants/:id" element={<MerchantDetails/>}/>
                <Route exact path="/curated-hints" element={<CuratedHints/>}/>
                <Route exact path="/orders" element={<Orders/>}/>
                <Route exact path="/license" element={<License/>}/>
                <Route exact path="/hints" element={<Hints/>}/>
                <Route exact path="/orders-and-returns" element={<OrdersandReturns/>}/>
                <Route exact path="/affiliate-groups" element={<AffiliateGroups/>}/>
                <Route exact path="/merchant-affiliates" element={<MerchantAffiliates/>}/>
                <Route exact path="/profile-settings" element={<ProfileSettings/>}/>
                <Route exact path="/profile-security" element={<Security/>}/>
                <Route exact path="/promotions" element={<Promotions/>}/>
                <Route exact path="/shopify-discounts" element={<ShopifyDiscounts/>}/>
                <Route exact path="/promotions-test" element={<PromotionsTest/>}/>
                <Route exact path="/analytics" element={<Analytics/>}/>
                <Route exact path="/suggested-hints" element={<SuggestedHints/>}/>
                <Route exact path="/manage-users" element={<Users/>}/>
                <Route exact path="/settings" element={<Settings/>}/>
                <Route exact path="/feedback" element={<Feedback/>}/>
                <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                <Route exact path="/reset-password" element={<ResetPassword/>}/>
            </Routes>
            <Toaster position="top-right"
                     toastOptions={{
                         duration: 5000,
                         style: {
                             background: '#363636',
                             color: '#fff',
                             fontSize: '18px',
                             borderRadius: '8px',
                             padding: '20px',
                         },
                         icon: '🔥',
                         success: {
                             style: {
                                 background: '#007600',
                             },
                             icon: '✅',
                         },
                         error: {
                             style: {
                                 background: '#F44738',
                             },
                             icon: '❌',
                         },
                     }}/>
        </>;
}

export default App
