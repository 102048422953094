import React, {useEffect, useRef, useState} from "react";
import axios from "../api/axios";
import {getPublicStoreUpgradeLink} from "../api/store";
import {requestUpgradeLicense} from "../api/licenses";
import toast from "react-hot-toast";
//import { useNavigate } from "react-router-dom";
const Navbar = () => {
    //const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');
    const stores = JSON.parse(localStorage.getItem('stores'));
    const store = localStorage.getItem('currentActiveStore');
    const store_id = localStorage.getItem('currentActiveStoreId');
    const license = localStorage.getItem('currentActiveStoreLicense');
    const store_is_public = localStorage.getItem('currentActiveStorePublic');
    const store_domain = localStorage.getItem('currentActiveStoreDomain');
    if (token === '' || token === null || token === undefined) {
        window.location = '/login';
    }
    const user_id = localStorage.getItem('userId');
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    };
    const [storeUpgradeUrl, setStoreUpgradeUrl] = useState('')
    const [storeDomain, setStoreDomain] = useState(store_domain)
    const [storeIsPublic, setStoreIsPublic] = useState(store_is_public)
    const [numberOfLicenses, setNumberOfLicenses] = useState(0)
    const [isSaving, setIsSaving] = useState(false)
    const [currentLicense, setCurrentLicense] = useState('')
    const modalRef = useRef(null);

    const handleLogout = async (event) => {
        event.preventDefault();
        try {
            await axios.get('/api/logout', config).then(result => {
                localStorage.setItem('accessToken', '');
                localStorage.setItem('menuAccess', []);
                localStorage.setItem('profileRole', '');
                localStorage.setItem('menuAccess', []);
                localStorage.setItem('currentModule', '');
                localStorage.setItem('stores', []);
                localStorage.setItem('role', result.data.role);
                localStorage.setItem('currentActiveStoreId', '');
                localStorage.setItem('currentActiveStoreLicense', '');
                localStorage.setItem('currentActiveStore', null);
                localStorage.setItem('currentActiveStoreLogo', null);
                localStorage.setItem('currentActiveStoreDomain', null);
                localStorage.setItem('currentActiveShopifyStoreId', null);
                window.location.href = '/login';
            }, (err) => {
                alert(err);
            });
        } catch (e) {
            console.log(e);
            alert(e);
        }
    }

    const getStoreUpgradeLink = async (storeDomain) => {
        const url = await getPublicStoreUpgradeLink(storeDomain)
        setStoreUpgradeUrl(url);
    }

    useEffect(() => {
        if (storeDomain && storeIsPublic === '0') getStoreUpgradeLink(storeDomain);
    }, [storeDomain, storeIsPublic])

    useEffect(() => {
        fetchProfile();
        setCurrentLicense(license);
    }, [])
    const fetchProfile = async () => {
        try {
            await axios.get('/api/profile/' + user_id, config).then(result => {
                localStorage.setItem('profileName', result.data.data[0].name);
                localStorage.setItem('profileEmail', result.data.data[0].email);
                localStorage.setItem('profileRole', result.data.data[0].role_name);
            }, (err) => {
                localStorage.setItem('accessToken', '');
                localStorage.setItem('menuAccess', []);
                localStorage.setItem('profileRole', '');
                window.location.href = '/login';
            });
        } catch (err) {
            console.log(err);
            localStorage.setItem('accessToken', '');
            localStorage.setItem('menuAccess', []);
            localStorage.setItem('profileRole', '');
            window.location.href = '/login';
        }
    }
    const handleChangeActiveStore = (id, store_name, store_logo, label, myshopify_domain, shopify_token, shopify_store_id, is_public) => {
        localStorage.setItem('currentActiveStoreId', id);
        localStorage.setItem('currentActiveStore', store_name);
        localStorage.setItem('currentActiveStoreLogo', store_logo);
        localStorage.setItem('currentActiveStoreLicense', label);
        localStorage.setItem('currentActiveStoreDomain', myshopify_domain);
        localStorage.setItem('currentActiveStorePublic', is_public);
        localStorage.setItem('currenctActiveShopifyToken', shopify_token);
        localStorage.setItem('currentActiveShopifyStoreId', shopify_store_id);
        window.location.reload();
    }

    const handleRequestUpgradeLicense = async () => {
        if (numberOfLicenses < 1) {
            toast.error('Please input number of licenses');
            return;
        }

        setIsSaving(true)
        try {
            await requestUpgradeLicense(store_id, numberOfLicenses, currentLicense)
            window.toastr.success('Successfully sent request to upgrade license', 'Good Job!');
        } catch (e) {
            console.error(e);
            toast.error('Failed to send upgrade license request', 'Oops!');
        }
        if (modalRef.current) window.$(modalRef.current).modal("hide");
        setIsSaving(false)
    }

    return (
        <>
            <nav
                className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                id="layout-navbar"
                style={{
                    position: 'fixed',
                    width: 'calc(100% - 312px)'
                }}
            >
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                    <a className="nav-item nav-link px-0 me-xl-4" href="">
                        <i className="bx bx-menu bx-sm"></i>
                    </a>
                </div>
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div className="navbar-nav align-items-center">
                        <div className="nav-item d-flex align-items-center d-none">
                            <i className="bx bx-search fs-4 lh-0"></i>
                            <input
                                type="text"
                                className="form-control border-0 shadow-none ps-1 ps-sm-2"
                                placeholder="Search..."
                                aria-label="Search..."/>
                        </div>
                    </div>
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        {localStorage.getItem('profileRole').toLowerCase() === 'merchant' &&
                            <>
                                <label> Select Store: </label>
                                <div className="dropdown mx-4">
                                    <button className="btn dropdown-toggle gh-select-store" type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                        <div className="d-flex justify-content-between align-middle">
                                            <span className="bx bxs-store-alt gh-h-px-20 me-2"
                                                  style={{marginTop: '2px'}}></span> {store}
                                        </div>
                                    </button>
                                    <ul className="dropdown-menu">
                                        {(stores).map(store => (
                                            <li className="" key={store.id}>
                                                {store_id !== store.uuid &&
                                                    <a className="dropdown-item d-flex justify-content-start align-middle"
                                                       href="#"
                                                       onClick={(e) => handleChangeActiveStore(store.uuid, store.store_name, store.store_logo, store.label, store.myshopify_domain, store.shopify_token, store.store_id, store.is_public)}>
                                                        <span className="bx bxs-store-alt gh-h-px-20 me-2"
                                                              style={{marginTop: '2px'}}></span>
                                                        <span className="">{store.store_name}</span>
                                                    </a>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>

                        }
                        {(localStorage.getItem('profileRole').toLowerCase() === 'merchant' && storeIsPublic != '1') &&
                            <span data-bs-toggle="modal" data-bs-target="#numLicenses"
                                  className="btn gh-yellow btn-buy-now no-transform ">
                        Upgrade or Add Licenses
                    </span>
                        }
                        {(localStorage.getItem('profileRole').toLowerCase() === 'merchant' && storeIsPublic == '1') &&
                            <span>
                      <a href={storeUpgradeUrl} className="btn gh-yellow btn-buy-now no-transform ">
                        Upgrade or Add Licenses
                      </a>
                    </span>
                        }

                        {false && localStorage.getItem('profileRole').toLowerCase() === 'merchant' &&
                            <span className="badge bg-label-primary me-1">{license}</span>
                        }

                        {
                            false && <li className="nav-item navbar-dropdown dropdown-user dropdown">
                                <a className="nav-link dropdown-toggle hide-arrow" href="" data-bs-toggle="dropdown">
                                    <div className="avatar avatar-online user-initials">
                                        {localStorage.getItem('profileName') != null && localStorage.getItem('profileName').charAt(0).toUpperCase()}
                                    </div>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar avatar-online user-initials">
                                                        {localStorage.getItem('profileName') != null && localStorage.getItem('profileName').charAt(0).toUpperCase()}
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span
                                                        className="fw-medium d-block">{localStorage.getItem('profileName')}</span>
                                                    <small
                                                        className="text-muted">{localStorage.getItem('profileRole').toUpperCase()}</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="d-none">
                                        <div className="dropdown-divider"></div>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/profile-settings">
                                            <i className="bx bx-user me-2"></i>
                                            <span className="align-middle">My Profile</span>
                                        </a>
                                    </li>
                                    <li className="d-none">
                                        <a className="dropdown-item" href="#">
                                            <i className="bx bx-cog me-2"></i>
                                            <span className="align-middle">Settings</span>
                                        </a>
                                    </li>
                                    {localStorage.getItem('profileRole').toLowerCase() == 'merchant' &&
                                        <li className="d-none">
                                            <a className="dropdown-item" href="#">
                            <span className="d-flex align-items-center align-middle">
                              <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                              <span className="flex-grow-1 align-middle ms-1">Billing</span>
                              <span
                                  className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                            </span>
                                            </a>
                                        </li>
                                    }
                                    <li>
                                        <div className="dropdown-divider"></div>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={handleLogout}>
                                            <i className="bx bx-power-off me-2"></i>
                                            <span className="align-middle">Log Out</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        }

                    </ul>
                </div>
            </nav>
            <div className="modal fade" id="numLicenses" tabIndex="-1" aria-hidden="true" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <div className="mb-6">
                                <h4 className="mb-2">Upgrade or Add Licenses</h4>
                            </div>
                            <hr/>
                            <form className="row g-6">
                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label mb-0"
                                               htmlFor="modalEditUserFirstName">Input number of licenses to add:</label>
                                        <input
                                            type="number"
                                            className="form-control w-50"
                                            min={1}
                                            value={numberOfLicenses}
                                            onChange={(e) => {
                                                setNumberOfLicenses(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr/>
                                    {!isSaving ? (
                                        <button type="submit" className="btn btn-warning me-3" onClick={handleRequestUpgradeLicense}>Confirm</button>
                                    ) : (
                                        <button type="submit" disabled className="btn btn-primary me-3">Saving...</button>
                                    )}
                                    <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal"
                                            data-dismiss="modal"
                                            aria-label="Close">Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Navbar
