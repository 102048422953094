import React, {useState, useEffect, useMemo} from "react";
import axios from "../api/axios";
import direct_axios from 'axios';
import ValueBox from "../components/ValueBox";
import {formatPrice, formatQty} from "../helpers/number";
import {getOrdersByStoreUuid} from "../api/orders";
import DateRange from "../components/DateRange";
import DataTable from "react-data-table-component";
import moment from "moment";
import FilterDatatable from "../components/FilterDatatable";
import {OverlayTrigger} from 'react-bootstrap';

const Affilliates = () => {
    const [role, setRole] = useState(localStorage.getItem('profileRole'));
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [originalOrders, setOriginalOrders] = useState([]);
    const [len, setLen] = useState(0);
    const [affiliateDetails, setAffiliateDetails] = useState([]);
    const [commisionRate, setCommissionRate] = useState(0);
    const stores = JSON.parse(localStorage.getItem('stores'));
    const [totalGMV, setTotalGMV] = useState('0.00');
    const [totalComission, setTotalComission] = useState('0.00');
    const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;

    /* setting of forms ends here */

    /* token and headers*/
    const token = localStorage.getItem('accessToken');
    if (token === '' || token === undefined || token === null) {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('menuAccess', []);
        localStorage.setItem('profileRole', '');
        window.location.href = '/login';
    }
    const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
    const currentActiveStoreName = localStorage.getItem('currentActiveStore');
    let store_id = currentActiveStoreId === '' || currentActiveStoreId === undefined ? 1 : currentActiveStoreId;
    let store_name = currentActiveStoreName === '' || currentActiveStoreName === undefined ? '' : currentActiveStoreName;
    const [selectedStore, setSelectedStore] = useState(currentActiveStoreId);
    const [storeId, setStoreId] = useState(store_id);
    const [storeName, setStoreName] = useState(store_name);

    const userId = localStorage.getItem('userId');
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    };

    const [openDateRangeFilter, setOpenDateRangeFilter] = useState(false);
    const [currentFilters, setCurrentFilters] = useState({search: ''});
    const [last12Months, setLast12Months] = useState([]);

    // Search Filter
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    useEffect(() => {
        fetchTable();
        setIsLoading(true);
    }, []);

    useEffect(() => {
        // Trigger fetchTable when storeId changes
        fetchTable();
    }, [storeId]);

    /*
    const fetchTable = async () => {
      // Set loading state and clear previous orders
      setIsLoading(true);
      setOrders([]);

      // Define the URL for the API request
      const url = '/api/order/listbystore2/' + store_id;

      try {
        // Fetch data from the API
        const result = await axios.get(url, config);

        // Set loading state to false once the request completes
        setIsLoading(false);

        // Access and set the orders data
        setOrders(result.data.data.data);
        setLen(result.data.data.data.length>0?result.data.data.data.length:0);

        // Access and set the affiliate details
        const affiliateData = result.data.affiliate;
        setAffiliateDetails(affiliateData);

        // Set the commission rate (affiliate_commission_percentage)
        setCommissionRate(affiliateData.affiliate_commission_percentage);

        //Get Hint for now to get total GMV for this storeid
        let external_url = store_id===null?external_api+'/hint':external_api+'/hint?store_id='+store_id;
        try{
          await direct_axios.get(external_url, config ).then(result => {
            console.log(result);
            //setHints(result.data.data);
            //setLen(result.data.data.length);
            //setIsLoading(false);

            const total = result.data.data.reduce((sum, product) => sum + product.price, 0);
            const totalComission = (affiliateData.affiliate_commission_percentage/100) * total;
            setTotalGMV(total);
            setTotalComission(totalComission);
            //console.log(result);
          })
        }catch(e){
          console.log(e);
          setIsLoading(false);
        }

      } catch (e) {
        // Handle error by logging it and resetting loading state
        console.log(e);
        setIsLoading(false);
      }
    };
    */

    const fetchTable = async () => {
        // Set loading state and clear previous orders
        setIsLoading(true);
        setOrders([]);

        // Define the URL for the API request
        const url = '/api/affiliates/listbystore/' + store_id;

        try {
            // Fetch data from the API
            const result = await axios.get(url, config);

            // Access and set the orders data

            // Access and set the affiliate details
            const affiliateData = result.data.data[0];
            setAffiliateDetails(affiliateData);

            // Set the commission rate (affiliate_commission_percentage)
            setCommissionRate(affiliateData.affiliate_commission_percentage);

            //Get Hint for now to get total GMV for this storeid
            let external_url = store_id === null ? external_api + '/order' : external_api + '/order?store=' + storeName + '&status=PURCHASED';
            console.log(external_url);
            try {

                const [orders, customers] = await Promise.all([
                    getOrdersByStoreUuid(storeId, role === 'merchant' ? {status: 'PURCHASED'} : {}),
                ])

                // await direct_axios.get(external_url, config ).then(result => {
                console.log(orders.data);
                const total = orders.data.reduce((sum, product) => {
                    // Check if the product's hint is not 'CANCEL' before adding its price
                    if (product.status !== 'CANCEL') {
                        return sum + product.hint.price;
                    }
                    return sum;
                }, 0);

                const totalComission = (affiliateData.affiliate_commission_percentage / 100) * total;
                setOrders(orders.data);
                setOriginalOrders(orders.data);
                setLen(orders.data.length > 0 ? orders.data.length : 0);
                setTotalGMV(total);
                setTotalComission(totalComission);
                setIsLoading(false);
                // })
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }

        } catch (e) {
            // Handle error by logging it and resetting loading state
            console.log(e);
            setIsLoading(false);
        }
    };

    const handleChange = async (event) => {
        const newStoreId = event.target.value;
        setStoreId(newStoreId); // This will trigger useEffect that calls fetchTable
        setSelectedStore(newStoreId);
        localStorage.setItem('currentActiveStoreId', newStoreId);
    };

    function formatDate(date) {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    }

    const handleFilterByDateFrom = (filterDateFrom) => {
        setCurrentFilters({
            ...currentFilters,
            filterDateFrom,
        })
    }

    const handleFilterByDateTo = (filterDateTo) => {
        setCurrentFilters({
            ...currentFilters,
            filterDateTo,
        })
    }

    const handleFilterByLockDate = (filterLockDate) => {
        const date = filterLockDate.year + '-' + filterLockDate.month + '-01';
        const filterDate =  moment(date, "YYYY-MM-DD").subtract(1, 'month');
        const dateFrom =  filterDate.format("YYYY-MM-DD");
        const lastDayOfMonth = filterDate.endOf("month");
        const dateTo = lastDayOfMonth.format("YYYY-MM-DD");
        setCurrentFilters({
            ...currentFilters,
            filterDateFrom: dateFrom,
            filterDateTo: dateTo,
        })
    }

    useEffect(() => {
        let tempOrders = [...originalOrders];
        const filters = Object.keys(currentFilters);
        for (let i = 0; i < filters.length; i++) {
            const filterType = filters[i];
            const filterValue = currentFilters[filterType];

            if (filterType === 'filterDateFrom') {
                if (filterValue) {
                    tempOrders = tempOrders.filter(h => new Date(h.created_at) > new Date(`${filterValue}T00:00:00`));
                }
            } else if (filterType === 'filterDateTo') {
                if (filterValue) {
                    tempOrders = tempOrders.filter(h => new Date(h.created_at) < new Date(`${filterValue}T23:59:59`));
                }
            } else if (filterType === 'search') {
                if (filterValue) {
                    const normalizedFilter = filterValue.toLowerCase();
                    tempOrders = tempOrders.filter(order => {
                        const name = order?.hint?.name;
                        if (!name) return false;
                        return name.toLowerCase().includes(normalizedFilter);
                    });
                }
            }
        }
        setOrders(tempOrders);
    }, [currentFilters, originalOrders]);

    useEffect(() => {
        const last12Mons = Array.from({length: 12}, (_, i) => {
            const month = moment().subtract(i, "months");
            return {
                name: month.format("MMMM"),
                month: month.format('MM'),
                year: month.format('YYYY')
            }
        });
        setLast12Months(last12Mons);
    }, [])

    const columns = [
        ...(role.toLowerCase() === 'admin' ? [{
            name: "STORE",
            cell: (row) => <span className='text-wrap'>{row.hint.store}</span>,
        }] : []),
        {
            name: "PRODUCT NAME",
            cell: (row) => <span className='text-wrap'>{row.hint.name}</span>,
            minWidth: '28%', // Ensuring the PRODUCT NAME column spans enough width
        },
        {
            name: "DATE",
            selector: (row) => row.created_at,
            sortable: true,
            cell: (row) => <span>{new Date(row.created_at).toLocaleDateString('en-us')}</span>,
            width: '8%',
            sortFunction: (a, b) => (new Date(a.created_at) - new Date(b.created_at)),
        },
        {
            name: "RECIPIENT",
            selector: (row) => row.purchased_for_userId,
            cell: (row) => <span
                className='text-wrap'>{`${row.purchased_for_user?.first_name} ${row.purchased_for_user?.last_name}`}</span>,
            minWidth: '8%',
            sortable: true,
            sortFunction: (a, b) => {
                const aName = `${a.purchased_for_user?.first_name} ${a.purchased_for_user?.last_name}`;
                const bName = `${b.purchased_for_user?.first_name} ${b.purchased_for_user?.last_name}`;
                return aName?.localeCompare(bName);
            },
        },
        {
            name: "BUYER",
            selector: (row) => row.purchased_by_userId,
            cell: (row) => <span
                className='text-wrap'>{`${row.purchased_by_user?.first_name} ${row.purchased_by_user?.last_name}`}</span>,
            minWidth: '8%',
            sortable: true,
            sortFunction: (a, b) => {
                const aName = `${a.purchased_by_user?.first_name} ${a.purchased_by_user?.last_name}`;
                const bName = `${b.purchased_by_user?.first_name} ${b.purchased_by_user?.last_name}`;
                return aName?.localeCompare(bName);
            },
        },
        {
            name: "GMV",
            cell: (row) => <span>${row.hint.price > 0 ? row.hint.price.toFixed(2) : '0.00'}</span>,
            sortable: true,
            sortFunction: (a, b) => (a.hint.price - b.hint.price),
        },
        {
            name: "RATE",
            cell: (row) => <span>{commisionRate}%</span>,
            sortable: false,
        },
        {
            name: "COMM.",
            cell: (row) => `$${row.hint.price > 0 ? (row.hint.price * (commisionRate / 100)).toFixed(2) : '0.00'}`,
            sortable: true,
            sortFunction: (a, b) => ((a.hint.price * (commisionRate / 100)) - (b.hint.price * (commisionRate / 100))),
        },
    ];


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterDatatable
                onFilter={e => {
                    setCurrentFilters({
                        ...currentFilters,
                        search: e.target.value,
                    });
                    setFilterText(e.target.value);
                }}
                onClear={handleClear}
                filterText={filterText}
                placeholder={'Search product name'}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const lockDateInfo = (
        <div className='w-50 bg-white pt-2 px-3 pb-3' style={{
            maxWidth: '400px',
            boxShadow: "rgba(0, 0, 0, 0.35) 2px 4px 8px",
            borderRadius: '10px',
        }}>
            <h4 className="modal-title text-center mb-3"
                style={{
                    color: '#101B6D',
                    fontWeight: '500',
                    fontSize: '18px',
                    lineHeight: '20px'
                }}>
                What is Lock Date?
            </h4>
            <h1 className={'d-flex justify-content-center text-center'} style={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                marginBottom: '0',
            }}>
                The end of the month following the transaction occurred.
            </h1>
        </div>
    );

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="main-kpis">
                <div className="row w-100 m-0 justify-content-between">
                    <ValueBox
                        label={'Affiliates'}
                        value={formatQty(len)}
                        loading={isLoading}
                        icon={'tf-icons bx bx-package'}
                        iconColor={'#93C05C'}
                    />
                    <ValueBox
                        label={'Total GMV'}
                        // value={totalGMV > 0 ? `$${totalGMV.toFixed(2)}` : '$0.00'}
                        value={totalGMV > 0 ? `$${formatPrice(totalGMV)}` : '$0.00'}
                        loading={isLoading}
                        icon={'bx bx-line-chart'}
                        iconColor={'#FE91DA'}
                    />
                    <ValueBox
                        label={'Conversion Rate'}
                        value={'0%'}
                        loading={isLoading}
                        icon={'tf-icons bx bx-chart'}
                        iconColor={'#CE73E0'}
                        comingSoon={true}
                    />
                    <ValueBox
                        label={'Total Commisions'}
                        // value={'$' + totalComission>0?totalComission.toFixed(2):'0.00'}
                        value={'$' + totalComission > 0 ? formatPrice(totalComission) : '0.00'}
                        loading={isLoading}
                        icon={'bx bx-money'}
                        iconColor={'#79CC13'}
                    />
                    <ValueBox
                        label={'Commission Rate'}
                        value={commisionRate + '%'}
                        loading={isLoading}
                        icon={'tf-icons fas fa-percent'}
                        iconColor={'#FF7D80'}
                    />
                </div>
            </div>
            {role.toLowerCase() === 'admin' &&
                <div className="main-kpis">
                    <select className="form-select border border-default rounded w-20 ms-1 gh-appearance-none"
                            onChange={handleChange} value={selectedStore}>
                        <option value="">All Stores</option>
                        {stores.map((store, index) => (
                            <option key={index} value={store.uuid}>
                                {store.store_name}
                            </option>
                        ))}
                    </select>
                </div>
            }
            <div className="row mb-12">
                <div id="table-list-container" className="col-md-12">
                    <div className="card">
                        <h5 className="card-header">
                            <a href="" className="btn btn-primary btn-sm ms-2 btn-create-promotion"> Invoice </a>
                            <div className="kpi-card-info">
                                <span>Gyfthint</span>
                                <p>Affiliate Commissions</p>
                            </div>
                            <hr/>
                            <div className='d-flex'>
                                <div className="dropdown me-1">
                                    <button className="btn dropdown-toggle gh-select-store" type="button"
                                            onClick={() => setOpenDateRangeFilter(!openDateRangeFilter)}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.99967 13.6673C3.31777 13.6673 0.333008 10.6825 0.333008 7.00065C0.333008 3.31875 3.31777 0.333984 6.99967 0.333984C10.6815 0.333984 13.6663 3.31875 13.6663 7.00065C13.6663 10.6825 10.6815 13.6673 6.99967 13.6673ZM6.99967 12.334C9.94521 12.334 12.333 9.94618 12.333 7.00065C12.333 4.05513 9.94521 1.66732 6.99967 1.66732C4.05415 1.66732 1.66634 4.05513 1.66634 7.00065C1.66634 9.94618 4.05415 12.334 6.99967 12.334ZM7.66634 7.00065H10.333V8.33398H6.33301V3.66732H7.66634V7.00065Z"
                                                    fill="#4B5563"/>
                                            </svg>
                                            <span className='ms-2'>Date Range</span>
                                        </div>
                                    </button>
                                    <DateRange
                                        openDateRangeFilter={openDateRangeFilter}
                                        setOpenDateRangeFilter={setOpenDateRangeFilter}
                                        currentFilters={currentFilters}
                                        handleFilterByDateFrom={handleFilterByDateFrom}
                                        handleFilterByDateTo={handleFilterByDateTo}/>
                                </div>
                                <div className="dropdown me-1">
                                    <button className="btn dropdown-toggle gh-select-store" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <svg  width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.99935 14.6667C3.63268 14.6667 3.3189 14.5362 3.05802 14.2754C2.79713 14.0145 2.66646 13.7005 2.66602 13.3334V6.66669C2.66602 6.30002 2.79668 5.98624 3.05802 5.72535C3.31935 5.46447 3.63313 5.3338 3.99935 5.33335H4.66602V4.00002C4.66602 3.0778 4.99113 2.2918 5.64135 1.64202C6.29157 0.992243 7.07757 0.667132 7.99935 0.666687C8.92113 0.666243 9.70735 0.991354 10.358 1.64202C11.0087 2.29269 11.3336 3.07869 11.3327 4.00002V5.33335H11.9993C12.366 5.33335 12.68 5.46402 12.9413 5.72535C13.2027 5.98669 13.3331 6.30047 13.3327 6.66669V13.3334C13.3327 13.7 13.2022 14.014 12.9413 14.2754C12.6805 14.5367 12.3665 14.6671 11.9993 14.6667H3.99935ZM3.99935 13.3334H11.9993V6.66669H3.99935V13.3334ZM7.99935 11.3334C8.36602 11.3334 8.68002 11.2029 8.94135 10.942C9.20268 10.6811 9.33313 10.3671 9.33268 10C9.33224 9.63291 9.20179 9.31913 8.94135 9.05869C8.6809 8.79824 8.3669 8.66758 7.99935 8.66669C7.63179 8.6658 7.31802 8.79647 7.05802 9.05869C6.79802 9.32091 6.66735 9.63469 6.66602 10C6.66468 10.3654 6.79535 10.6794 7.05802 10.942C7.32068 11.2047 7.63446 11.3351 7.99935 11.3334ZM5.99935 5.33335H9.99935V4.00002C9.99935 3.44447 9.8049 2.97224 9.41602 2.58335C9.02713 2.19447 8.5549 2.00002 7.99935 2.00002C7.44379 2.00002 6.97157 2.19447 6.58268 2.58335C6.19379 2.97224 5.99935 3.44447 5.99935 4.00002V5.33335Z"
                                                    fill="#4B5563"/>
                                            </svg>
                                            <span className='ms-2'>Lock Date</span>
                                        </div>
                                    </button>

                                    <OverlayTrigger
                                        trigger='hover'
                                        placement="right"
                                        overlay={lockDateInfo}
                                        popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [13, 10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <a className="my-anchor-element ms-1 cursor-pointer"
                                           data-bs-toggle="popover" data-bs-trigger="focus"
                                        >
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.9167 8.2487H10.0834V6.41536H11.9167M11.9167 15.582H10.0834V10.082H11.9167M11 1.83203C9.79626 1.83203 8.60426 2.06913 7.49211 2.5298C6.37996 2.99047 5.36943 3.66568 4.51823 4.51689C2.79914 6.23597 1.83337 8.56755 1.83337 10.9987C1.83337 13.4298 2.79914 15.7614 4.51823 17.4805C5.36943 18.3317 6.37996 19.0069 7.49211 19.4676C8.60426 19.9283 9.79626 20.1654 11 20.1654C13.4312 20.1654 15.7628 19.1996 17.4819 17.4805C19.2009 15.7614 20.1667 13.4298 20.1667 10.9987C20.1667 9.79491 19.9296 8.60292 19.4689 7.49077C19.0083 6.37861 18.3331 5.36809 17.4819 4.51689C16.6306 3.66568 15.6201 2.99047 14.508 2.5298C13.3958 2.06913 12.2038 1.83203 11 1.83203Z"
                                                    fill="#FDA328"/>
                                            </svg>
                                        </a>
                                    </OverlayTrigger>

                                    <ul className="dropdown-menu">
                                        {
                                            last12Months.map(m => (
                                                <li key={m.name} className="dropdown-item" onClick={() => {
                                                    handleFilterByLockDate(m)
                                                }}>
                                                    {m.name} ({m.year})
                                                </li>
                                            ))
                                        }
                                        {/*<li className="dropdown-item">All Dates</li>*/}
                                        {/*<li className="dropdown-item" onClick={() => {handleFilterByCampaign('all')}}>All Campaigns</li>*/}
                                    </ul>
                                </div>
                                {/* Show applied filters */}
                                {
                                    Object.keys(currentFilters).map(filter => {
                                        if (filter === 'search') return (<span key={filter}></span>);
                                        let dateFormat = '';
                                        if ((filter === 'filterDateFrom' || filter === 'filterDateTo') && currentFilters[filter] !== '') {
                                            const d = currentFilters[filter].split('-'); // YYYY-MM-DD -> MM/DD/YYYY
                                            dateFormat = d[1] + '/' + d[2] + '/' + d[0];
                                        }
                                        return (
                                            <div className='gh-applied-filter-item ps-3 mx-1 text-capitalize'
                                                 key={filter}>
                                                {filter === 'filterDateTo' && 'Up To: '}
                                                {filter === 'filterDateFrom' && 'From: '}
                                                {(filter === 'filterDateFrom' || filter === 'filterDateTo') && dateFormat}
                                                {(filter !== 'filterDateFrom' && filter !== 'filterDateTo') && currentFilters[filter].toLowerCase().replace('_', ' ')}
                                                <span className='ms-2 me-1 cursor-pointer' onClick={() => {
                                                    const temp = {...currentFilters};
                                                    delete temp[filter];
                                                    setCurrentFilters(temp);
                                                }}>
                                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.23341 8.58341L0.416748 7.76675L3.68341 4.50008L0.416748 1.23341L1.23341 0.416748L4.50008 3.68341L7.76675 0.416748L8.58341 1.23341L5.31675 4.50008L8.58341 7.76675L7.76675 8.58341L4.50008 5.31675L1.23341 8.58341Z"
                                                        fill="#4B5563"/>
                                                </svg>
                                            </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </h5>
                        {/*<div className="table-responsive text-nowrap">*/}
                        {/*    <hr/>*/}
                        {/*        <DataTable*/}
                        {/*          columns={columns}*/}
                        {/*          data={filteredHints}*/}
                        {/*          noDataComponent=""*/}
                        {/*          highlightOnHover*/}
                        {/*          persistTableHead*/}
                        {/*          pagination*/}
                        {/*          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1*/}
                        {/*          subHeader*/}
                        {/*          subHeaderComponent={subHeaderComponentMemo}*/}
                        {/*        />*/}
                        {/*</div>*/}
                        <div className="table-responsive text-nowrap">
                            <hr/>
                            <DataTable
                                columns={columns}
                                data={orders}
                                noDataComponent=""
                                highlightOnHover
                                persistTableHead
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                            <table className="table d-none">
                                <thead>
                                <tr>
                                    {role.toLowerCase() === 'admin' && <th>Store</th>}
                                    <th>Product Name</th>
                                    <th>Date</th>
                                    <th>Recipient</th>
                                    <th>Buyer</th>
                                    <th>GMV</th>
                                    <th>Rate</th>
                                    <th>Commission</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(Array.isArray(orders) ? orders : []).map(order => (
                                    <tr key={order.id}>
                                        {role.toLocaleLowerCase() === 'admin' && <td>{order.hint.store}</td>}
                                        <td><span style={{
                                            display: 'block',
                                            maxWidth: '370px',
                                            whiteSpace: 'wrap'
                                        }}>{order.hint.name}</span></td>
                                        <td>{formatDate(order.created_at)}</td>
                                        <td>{order.purchased_by_user['first_name'] + ' ' + order.purchased_by_user['last_name']}</td>
                                        <td>{order.purchased_for_user['first_name'] + ' ' + order.purchased_for_user['last_name']}</td>
                                        <td>${order.hint.price > 0 ? order.hint.price.toFixed(2) : '0.00'}</td>
                                        <td><span>{commisionRate}%</span></td>
                                        <td>{'$' + (order.hint.price * [commisionRate / 100]).toFixed(2)}</td>
                                    </tr>
                                ))}
                                {len === 0 && !isLoading &&
                                    <tr>
                                        <td colSpan={9}>No records found.</td>
                                    </tr>
                                }
                                {isLoading &&
                                    <tr>{role.toLowerCase() === 'admin' && <td>
                                        <div className="loading-content"><h5 className="loading-long"></h5></div>
                                    </td>}
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                    </tr>
                                }
                                {isLoading &&
                                    <tr>{role.toLowerCase() === 'admin' && <td>
                                        <div className="loading-content"><h5 className="loading-short"></h5></div>
                                    </td>}
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-short"></h5></div>
                                        </td>
                                    </tr>
                                }
                                {isLoading &&
                                    <tr>{role.toLowerCase() === 'admin' && <td>
                                        <div className="loading-content"><h5 className="loading-long"></h5></div>
                                    </td>}
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                        <td>
                                            <div className="loading-content"><h5 className="loading-long"></h5></div>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Affilliates
