import React, { useState } from 'react';
import { FaArrowCircleDown } from 'react-icons/fa';

const ScrollButton = () => {

    const [visible, setVisible] = useState(true)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(false)
        }
        else if (scrolled <= 300) {
            setVisible(true)
        }
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className={'scroll-to-bottom'}>
            <FaArrowCircleDown className={'scroll-to-bottom-icon'} onClick={scrollToBottom}
                               style={{ display: visible ? 'flex' : 'none' }} />
        </div>
    );
}

export default ScrollButton;
