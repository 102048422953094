export const formatPrice = (num) => {
    if (num >= 1_000_000) {
        return Number((num / 1_000_000).toFixed(1).replace(/\.0$/, '')).toLocaleString('en-US') + 'm';
    } else if (num >= 1_000) {
        return Number((num / 1_000).toFixed(1).replace(/\.0$/, '')).toLocaleString('en-US') + 'k';
    } else {
        return new Intl.NumberFormat("en-US", {
            style: "decimal", // Use 'decimal' instead of 'currency'
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(num);
    }
}

export const formatQty = (num) => {
    return Number(num.toFixed(2)).toLocaleString('en-US');
}
